import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Stepper from './Stepper';

const theme = createTheme({
  palette: {
    background: {
      default: '#ebebeb',
    },
    mode: 'light',
    primary: {
      main: '#3688b1',
    },
    secondary: {
      main: '#e1ab25',
    },
  },
});

function SelectStepper() {
  return (
    <ThemeProvider theme={theme}>
      <Stepper />
    </ThemeProvider>
  );
}

export default SelectStepper;
