import React, { Fragment, useState } from 'react';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Typography,
} from '@mui/material';
import Copyright from '../Copyright';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';

const steps = [
  'What is a good contact email address?',
  'What would you like your PAAPI to be named?',
];

let render = 0;

function SelectStepper() {
  render = render + 1;

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const isEmailValid = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  };

  //validation of username
  const isUsernameValid = () => {
    if (username?.length >= 8) {
      return true;
    }
    return false;
  };

  //disables button until valid input
  const isButtonDisabled = () => {
    if (isLoading) return isLoading;
    if (activeStep == 1 || activeStep == 2) return !isUsernameValid();
    else return !isEmailValid();
  };

  //moves to the next question
  const handleNext = (activeStep, steps) => {
    if (activeStep === steps.length - 1) {
      handleSignUp();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  //moves to the previous question
  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  //signs up the user
  const handleSignUp = async () => {
    setIsLoading(true);
    const toastId = toast.loading('Signing up...');
    const { status } = await axios.post('https://grand.paapi.org/onboard', {
      email: email,
      username: username,
    });
    if (status == 200) {
      toast.success('Signed up!', {
        id: toastId,
      });
      setIsLoading(false);
      setIsComplete(true);
    } else {
      toast.success('Something went wrong!', {
        id: toastId,
      });
      setIsLoading(false);
    }
  };

  //either renders the stepper form or the finished page
  const renderContent = () => {
    if (isComplete) {
      return (
        <Box
          sx={{
            marginTop: 3,
            alignItems: 'center',
          }}
        >
          <Typography
            variant='h4'
            align={'center'}
            sx={{
              fontWeight: 700,
            }}
            marginBottom={4.5}
          >
            Welcome Onboard!
            {/* <span style={{ color: '#e1ab25' }}>PAAPI</span> */}
          </Typography>
          <Typography
            variant='h6'
            align={'center'}
            color={'textSecondary'}
            marginTop={4.5}
          >
            Please check your email for further instructions.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              pt: 4.5,
              mb: 6.3,
            }}
          >
            <Button
              variant='contained'
              disabled={false}
              href='https://paapi.org'
            >
              Return to Homepage
            </Button>
          </Box>
        </Box>
      );
    } else {
      return (
        <>
          <Typography
            variant='h4'
            align={'center'}
            sx={{
              fontWeight: 1000,
              mt: 2,
              mb: 0,
            }}
          >
            Onboard
          </Typography>
          <Typography textAlign='center'>
            Already have an account?{' '}
            <a
              style={{ color: 'rgb(54, 136, 177)', textDecoration: 'none' }}
              className='ml-4'
              href='https://auth.paapi.org'
            >
              Log in
            </a>
          </Typography>
          <Box
            sx={{
              marginTop: 3,
              alignItems: 'center',
            }}
          >
            {
              <Stepper activeStep={activeStep} orientation='vertical'>
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            }
          </Box>

          <Fragment>
            <Grid container marginTop={2.5}>
              <Grid item xs display='flex' justifyContent='center'>
                {activeStep == 1 || activeStep == 2 ? (
                  <TextField
                    id='outlined-basic'
                    label='Username'
                    variant='outlined'
                    color={
                      !isUsernameValid() && username !== ''
                        ? 'error'
                        : 'primary'
                    }
                    sx={{ width: '100%' }}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                ) : (
                  <TextField
                    id='outlined-basic'
                    label='Email'
                    variant='outlined'
                    color={
                      !isEmailValid() && email !== '' ? 'error' : 'primary'
                    }
                    sx={{ width: '100%' }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                )}
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              {activeStep > 0 && (
                <>
                  <Button
                    color='inherit'
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                </>
              )}
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                variant='contained'
                disabled={isButtonDisabled()}
                onClick={() => handleNext(activeStep, steps)}
              >
                {activeStep === steps.length - 1 ? 'Launch' : 'Next'}
              </Button>
            </Box>
          </Fragment>
        </>
      );
    }
  };

  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justifyContent='center'
      style={{
        minHeight: '100vh',
        backgroundImage: `url(/bg-1.jpg)`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Container
        component='main'
        maxWidth='xs'
        sx={{
          minHeight: '491.883px',
          backgroundColor: '#fff',
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          borderRadius: '15px',
        }}
      >
        <CssBaseline />
        <Box
          paddingTop={4}
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              width: 'auto',
              height: 'auto',
              maxWidth: '30%',
              maxHeight: '30%',
            }}
            src='/logo.png'
          />
        </Box>
        <Box noValidate sx={{ p: 1, pt: 0 }}>
          {renderContent()}
          <Copyright sx={{ mt: 4 }} />
        </Box>
      </Container>
      <Toaster position='top-center' reverseOrder={false} />
    </Grid>
  );
}

export default SelectStepper;
